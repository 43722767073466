import React, { Component } from 'react';
import './App.css';
import './Tasks.css';

import TaskContainer from './Tasks';

export default class App extends Component {
  
  state = {
    gapiReady: false,
    signedIn: false,
    data: null
  }

  initClient() {
    console.log("init", this)
    window.gapi.client.init({
        apiKey: 'AIzaSyAa4TAtUO637IOw5lMwLSA9xtu1IzhGOP0',
        clientId: '487328203590-k5og7kp6151hbkedi62qr4btsue1cefc.apps.googleusercontent.com',
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/tasks/v1/rest'],
        scope: 'https://www.googleapis.com/auth/tasks'
    }).then(function() {
      var signIn = window.gapi.auth2.getAuthInstance().isSignedIn
      signIn.listen(this.updateSigninStatus.bind(this));
      this.updateSigninStatus(signIn.get());
    }.bind(this), function(error) {
      console.log(JSON.stringify(error, null, 2));
    });
  }

  updateSigninStatus(isSignedIn) {
    this.setState({
      gapiReady: true,
      signedIn:isSignedIn
    });
    if (isSignedIn) {
      this.downloadTasks();
    }
  }

  tasksRequest(taskListId) {
    return window.gapi.client.request({
      path: `tasks/v1/lists/${taskListId}/tasks`,
      params: {
        'showCompleted': false
      }
    });
  }

  downloadTasks() {
    window.gapi.client.tasks.tasklists.list({
        // 'maxResults': 10
    }).then(function(response) {
      var taskLists = response.result.items;
      console.log("Downloaded Tasks:", response.result.items)

      if (taskLists && taskLists.length > 0) {
        var batch = window.gapi.client.newBatch();
        for (var i = 0; i < taskLists.length; i++) {
          var taskList = taskLists[i];
          batch.add(this.tasksRequest(taskList.id), { id: taskList.id });
        }
        batch.then( function(batchResponse) {
          var result = batchResponse.result;
          for (var i = 0; i < taskLists.length; i++) {
            var taskList = taskLists[i];
            taskList.items = result[taskList.id].result.items
          }
          this.setTaskData(taskLists)
          this.setState({data: taskLists});          
        }.bind(this));
      } else {
      }
    }.bind(this));
  }


  updateTask(taskId, taskListId, patch) {
    console.log(taskId, taskListId, patch)

    window.gapi.client.tasks.tasks.patch(
      {'task': taskId, 'tasklist': taskListId, 'resource':patch}
    ).then(function(response) {
      console.log(response)
      this.clearTaskData();
    }.bind(this));
  }

  getTaskData() {
    return JSON.parse(localStorage.getItem('taskLists'))
  }
  setTaskData(data) {
    localStorage.setItem('taskLists', JSON.stringify(data))
  }
  clearTaskData() {
    localStorage.removeItem('taskLists')
  }

  componentDidMount() {
    this.setState({data:this.getTaskData()})
    if (window.gapi)
      window.gapi.load('client', this.initClient.bind(this));  
  }

  signIn() {
    window.gapi.auth2.getAuthInstance().signIn(); 
  }

  signOut() {
    // this.clearTaskData();
    window.gapi.auth2.getAuthInstance().signOut(); 
  }

  render() {

    const Loaded = this.state.gapiReady || this.state.data;

    let App;

    if (this.state.signedIn || this.state.data) {
      App = (
        <div className="App">
          <div className="toolbar">
            <button className="signOut" onClick={this.signOut}>Sign out</button>
          </div>
          <TaskContainer app={this} data={this.state.data} />
        </div>
      )
    } else {
      App = (
        <div className="App">
          <div className="modal">
            <h2>task.supply</h2>
            <p>Renders your Google tasks in a multi-column view.
            Someday it will be sortable too.</p>
            <br/>
            <button onClick={this.signIn}>Sign in with Google</button>
          </div>
        </div>
      )
    }
    
    const Loading = (
      <div className = "modal">
        Loading...
      </div>
    )

    return (
      <div>
        {Loaded ? App : Loading}
      </div>
    )
  }
}
