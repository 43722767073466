import React, {useState} from 'react';
import './Tasks.css';
import {linkify, relativeDate, parseDate} from './util.js';

// Multi-column, one per tasklist

export default function TaskContainer(props) {
  if (props.data) {
    const TaskLists = props.data.map( taskList => {
      return <TaskList app={props.app} data={taskList} key={taskList.id} />
    })

    return (
      <div className="tasksContainer">
        {TaskLists}
      </div>
    )
  }

  return <div className="modal">Fetching Data...</div>
}



// Groups of tasks in a list

function TaskList(props) {

  var data = props.data
  var tasks = data.items
  var subtasksByParent = {}
  
  if (tasks) {

    // var tasksById = tasks.reduce((obj, item) => {
    //    obj[item.id] = item;
    //    return obj;
    //  }, {})

    // Find items with subtasks
    tasks.forEach(task => {
      if (task.parent) {
        // var parent = tasksById[task.parent]
        if (subtasksByParent[task.parent]) {
          subtasksByParent[task.parent].push(task)
        } else {
          subtasksByParent[task.parent] = [task];
        }
      }
      task.dueDate = task.due ? new Date(task.due) : undefined;
    });

    // Sort
    if (true) {
      let now = new Date()
      tasks.sort((a, b) => {
        if (b.dueDate && (!a.dueDate || a.dueDate > now || a.dueDate > b.dueDate)) return 1
        if (a.dueDate && (!b.dueDate || b.dueDate > now || a.dueDate < b.dueDate)) return -1
        return 0;
      });
    }

    var children = []
    // var now = new Date();
    // var lastDueDate = new Date(0);
    tasks.forEach( task => {
      if (task.parent !== undefined) return;

      let subTaskCells
      const subtasks = subtasksByParent[task.id]
      if (subtasks) {
        subTaskCells = subtasks.map(subtask => {
          return <TaskCell parentId={data.id} data={subtask} key={subtask.id} isSubtask={true} />
        });
      }

      // var overdue = task.dueDate ? task.dueDate < now : false;

      // if ((lastDueDate && !task.dueDate) ||(task.dueDate - lastDueDate > 1)) {
      //   children.push(SectionCell({className: overdue ? "overdue" : "", title:relativeDate(task.dueDate) || "No Date"}))
      // }
      // lastDueDate = task.dueDate;
      children.push(
        <div key={task.id + "wrapper"}>
          <TaskCell app={props.app} parentId={data.id} data={task} key={task.id} />
          {subTaskCells}
        </div>
      );
      


    })

    return (
      <div className="taskList">
        <h1>{data.title}</h1>
        {children}
      </div>
    )

  }
  return ("");

}

// function SectionCell(props) {
//   return (
//     <div className={"groupTitle " +  props.className}>{props.title}</div>
//   )
// }
// Individual task cells

function TaskCell(props) {

  var data = props.data;
  var date = data.due ? parseDate(data.due) : undefined;
  var dateString = relativeDate(date);

  const [checked, setChecked] = useState(data.status === 'completed');

  var classList = ['task'];
  if (new Date() > date) {
    classList.push('overdue')
  }
  if (props.isSubtask) {
    classList.push('subtask')
  }
  if (checked) {
    classList.push('complete')
  }


  var links = props.data.links ? props.data.links.map( (link, i) => {
      return <a href={link.link} className={link.type} key={i}>{link.description}</a>
    }) : []

  return (
    <div className={classList.join(" ")}>
      <div className="checkbox">
      <input  
        id={data.id + "-checkbox"} 
        type="checkbox" 
        checked={checked}
        onChange={() => {
            var patch = checked ? 
              { status: 'needsAction', completed: null } :
              { status: 'completed' }  
            props.app.updateTask(data.id, props.parentId, patch)
            setChecked(!checked)
          }
        }
      />
      </div>
      <div className="due">{dateString}</div>
        <label className="title" htmlFor={data.id + "-checkbox"} dangerouslySetInnerHTML={{__html:linkify(data.title)}}></label>
      <div className="links">{links}</div>
      <div className="notes" dangerouslySetInnerHTML={{__html:linkify(data.notes)}}></div>
    </div>
  )
}