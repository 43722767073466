
export function linkify(inputText) {
  if (!inputText) return undefined;
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(?:https?|ftp):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)[-A-Z0-9+&@#%\/?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, function(string, url, title) {
      var paperRE = /https:\/\/(paper)\.dropbox\.com\/doc\/(?:[^#"<]*-)?(\w+)/;

      if (url.match(paperRE)) {
        var newTitle = titleForPaperURL(url)
        if (newTitle.length) title = newTitle
      }
      return `<a href="${url}}" target="_blank">${title}</a>`
    });

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
}

function titleForPaperURL(url) {
  var bits = url
    .split("/")
    .pop()
    .split("--")
    .shift()
    .split("-");
  // bits.pop();
  return bits.join(" ");
}

export function parseDate(input) {
  var parts = input.substring(0,10).split('-');
  return new Date(parts[0], parts[1]-1, parts[2]);
}

export function relativeDate(timestamp) {
  if (!timestamp) return undefined;
  var midnight = new Date();
  midnight.setHours(0,0,0,0); // last midnight
  var delta = Math.round((timestamp - midnight) / 1000);
  var minute = 60,
      hour = minute * 60,
      day = hour * 24,
      week = day * 7;

  if (delta < - (midnight.getDay()-1) * day) {
    return (-Math.round(delta / day)) + " days ago"
    return timestamp.toLocaleDateString(undefined, { month: 'long', day: 'numeric' });
  } else if (delta < -1 * day) {
    return timestamp.toLocaleDateString(undefined, { weekday: 'long' });
  } else if (delta < 0 * day) {
    return "Yesterday";
  } else if (delta < 1 * day) {
    return "Today"
  } else if (delta < 2 * day) {
    return "Tomorrow"
  } else if (delta < 1 * week) {
    return timestamp.toLocaleDateString(undefined, { weekday: 'short' });
  } else {

    return timestamp.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }
}
